@import "fonts.css";
a {
  color: #00000080;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.iconMenu{
    padding-top: 1rem;
    padding-left: 1rem;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: #f8f9fa;
    border-color: #dae0e5;
    border-width: 1px;
    border-style: solid;
    z-index: 3;
}
.Icon-Menu {
  margin-right: 0.3rem;
  margin-left: 0.3rem;
}

.nav-icon--chart{
  margin-right: 0.625rem;
  display: inline-block;
}

.Input-Title {
  width: 100%;
  font-family: "LatoRegular";
  font-size: 1.1rem;
  border-radius: 0.3rem;
  color: rgb(70, 67, 67);
  padding: 0.3rem;
}

.Input-Message {
  width: 100%;
  font-family: "LatoSemiBold";
  font-size: 1rem;
  color: rgb(70, 67, 67);
  border-radius: 0.3rem;
  padding: 0.3rem;
}

/* nuevo menu lateral AHORITA ORDENAR*/
.Icon-Menu-Lateral{
  display: block;
  margin: auto;
}

.Lateral{
  width: 110px;
  /*height: calc(100% - 90px);*/
  height: 100%;
  background: #fff;
  z-index: 3;
  position: absolute;
  transition: transform .3s;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0;
  border-color: #dae0e5;
  border-width: 1px;
  border-style: solid;
}

.Menu-Item-Title-2{
  font-family: "Gotham";
  font-size: 11px;
  text-align: center;
  margin: auto;
}

.scroll{
  padding-right: unset;
  margin-right: unset;
  height: 100%;
}

.sidebar .scrollbar-container{
  margin-right: 0;
  padding-right: 0;
  position: relative;
  height: 100%;
}

.ps{
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
}

.flex-column{
  flex-direction: column !important;
}

.nav{
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 12px;
  list-style: none;
}

.navbar-brand{
  padding-top: 0px;
}

.nav-item{
  text-align: center;
}

.nav-link{
  padding: 0px;
}

#sub-menu{
  transform: translateX(0);
  background: #fff;
  z-index: 3;
  position: fixed;
  left: calc(0% + 111px);
  border-left: 1px solid #f3f3f3;
  box-shadow: 0 3px 30px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 10%);
  transition: transform .3s;
  /*height: calc(100% - 90px);*/
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
}
/* .Lateral {
  padding-top: 1rem;
  padding-left: 1rem;
  width: 20rem;
  height: 120%;
  position: absolute;
  left: 0;
  background-color: #f8f9fa;
  border-color: #dae0e5;
  border-width: 1px;
  border-style: solid;
  z-index: 3;
} */


#decorative-line{
  background-color: rgb(135, 173, 54);
  height: 40vh;
  width: 10px;
}

#photoPerfil{
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

#email_perfil{
  font-family: Lato;
  font-size: 1.8vh;
  margin: auto;
  color: rgb(135, 173, 54);
}

#module_perfil{
  font-family: Lato;
  font-size: 2.8vh;
  margin: auto;
  color:#606060;
  text-align: center;
}

@media (max-width: 800px){
  .perfil{
    width: 28vh;
  }
  #photoPerfil{
    width: 40px;
    height: 40px;
  }
  #email_perfil, #module_perfil{
    font-size: 2vh;
  }
}


/*fin del nuevo navbar*/

.list-group-item {
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-family: "GothamRoundedBold";
  font-size: 1rem;
  color: #7c7c7d;
}

.list-group-item-action:focus,
.list-group-item-action:hover,
.list-group-item-action:active {
  background-color: rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  color: rgb(70, 67, 67);
}

.Menu-Icon {
  cursor: pointer;
  /* border-color: #dae0e5;
  border-width: 3px;
  border-style: solid;
  border-radius: 0.5rem;  */
}

/* .Menu-Icon:hover {
  border-width: 6px;
}  */

.Menu-Item .NavMenu-Item{
  font-family: "Gotham";
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
}

.Menu-Item-subMenu {
  font-family: "Gotham";
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  padding-right: 2rem;
}

.Menu-Item-Title {
  font-family: "Gotham";
  font-size: 1rem;
  margin-right: 1rem;
}

.Menu-Item:hover {
  color: rgb(70, 67, 67);
}

.Message {
  font-family: "Gotham";
  font-size: 1.2rem;
}

.Message-Feedback {
  font-family: "Gotham";
  font-size: 1.2rem;
}

.NoneNotification{
  border: 1px solid #dae0e5;
  width: 30vw;
  height: 40vh;
  z-index: 1;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: visibility 0.1s linear, opacity 0.1s linear;
  opacity: 1;
}

.Icon-noNotification{
  margin-left: auto;
  margin-right: auto;
}

.text-noNotification{
  text-align: center;
  font-family: "Gotham";
  font-size: 1.2rem;
}

.Notification {
  border: 1px solid #dae0e5;
  width: 30vw;
  height: 40vh;
  z-index: 1;
  position: absolute;
  right: 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: visibility 0.1s linear, opacity 0.1s linear;
  overflow: scroll;
  opacity: 1;
}

.NotificationVisible {
  opacity: 1;
}

.navbar-light .navbar-toggler {
  /*border-color: rgba(0, 0, 0, 0.1);*/
  background-color: #7c7c7d;
  color: #ffffff;
  width: 3rem;
  padding: 0.2rem;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='https://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar {
  /* border-color: #dae0e5;
  border-width: 1px;
  border-style: solid; */
  height: 4rem;
}

.popover-body {
  padding: 0rem;
}

.Pop-Up-Feedback {
  min-width: 60%;
  min-height: 40vh;
  margin-right: 2vw;
  margin-left: 6vw;
  margin-top: 8vh;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  position: fixed;
  border-radius: 1rem;
  z-index: 3;
  background-color: #f8f9fa;
  box-shadow: 1px 2px 3px 2px #9e9e9e;
}

.Row-Reverse {
  flex-direction: row-reverse;
}

.Switch-Icon {
  margin-left: 1.5rem;
  cursor: pointer;
  width: 9rem;
}

@media (max-width: 600px){
  .Switch-Icon{
    margin-left: 0;
    width: 5rem;
  }

  .NavMenu-Item{
    margin-right: 0rem;
  }
}

.Submit-Button-Form {
  border-radius: 1rem;
  border-image: none;
  border-style: solid;
  border-color: #84ac34;
  background-color: #84ac34;
  padding: 0.3rem;
  width: 50%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: "GothamRoundedBold";
  font-size: 1.2rem;
  color: #fff;
  max-width: 15rem;
}

textarea {
  resize: none;
}

.Title-Feedback {
  font-family: "Gotham";
  font-size: 1.2rem;
}

.nav-icon {
  color: #87ad36;
  font-size: 1.5rem;
}