$gray: #606060;

.reports {
    &__content {
        width: 86% !important;
    }

    &__list {
        li {
            color: $gray;
            margin-left: 1.875rem;
            margin-bottom: 0.3125rem;
        }
    }

    &__parent {
        margin-top: 0.625rem;
        margin-left: 0 !important;
        cursor: pointer;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 1.25rem;
        margin-top: 1.875rem;

        > button {
            display: flex;
            gap: 0.25rem;
            align-items: center;
            border: none;
            padding: 0.3125rem 1.25rem;
            border-radius: 0.25rem;
            transition: 0.4s all;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &:active {
                transform: scale(0.97);
            }
        }
    }

    .report {
        &__title {
            text-align: center;
            font-size: 22px;
            margin: 30px 0;
        }

        &__filters {
            display: flex;
            gap: 20px;
        }

        &__select {
            width: 200px;
        }

        &__dates {
            display: flex;
            gap: 20px;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: end;
        }

        &__pdf-button {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 0.75rem;
            padding: 0;
            border: none;
            outline: none;

            > img {
                cursor: pointer;
            }
        }

        &__content {
            background-color: white;
            padding: 30px 20px;
            // border-radius: 12px;
            margin: auto;
            max-width: 900px;
            width: 100%
        }

        &__total {
            &--value {
                text-align: center;
                font-size: 1.25rem;
                font-weight: bold;
                text-decoration: underline;
                line-height: 1.25rem;
                margin-bottom: 0.125rem;
            }

            &--text {
                font-size: 0.875rem;
                text-align: center;
            }
        }

        &__charts {
            display: flex;
        }
    }
}
